class CoumpoundService {

    get_orders(page, data) {
        return axios.post('orders?page=' + page, data);
    }
    create_order() {
        return axios.get('orders/create');
    }
    store_order(data) {
        return axios.post('orders/store', data)
    }
    edit_order(id) {
        return axios.get('orders/edit/' + id)
    }
    update_order(id, data) {
        return axios.post('orders/update/' + id, data)
    }
    forward_order(id, data) {
        return axios.post('orders/forward-order/' + id, data)
    }
    rejected_order(id, data) {
        return axios.post('orders/reject-order/' + id, data)
    }
    upload_attachment(data) {
        console.log(data)
        return axios.post('orders/attachments/store', data)
    }

    delete_order(id) {
        return axios.delete('orders/destroy/' + id)
    }
    order_details(id) {
        return axios.get('orders/view/' + id)
    }
    warehouse_by_compound(id) {
        return axios.get('warehouses/warehouse-by-compound/' + id)
    }
    get_items() {
        return axios.get('iteams/get-items')
    }
    get_items_by_warehouse(id) {
        return axios.get('warehouses/get-content/' + id)
    }
    get_items_by_unit(id) {
        return axios.get('units/get-content/' + id)
    }
    store_item_details(data) {
        return axios.post('iteams/details/store', data)
    }
    edit_item_details(id) {
        return axios.get('iteams/details/edit/' + id)
    }
    delete_item_details(id) {
        return axios.delete('iteams/details/destroy/' + id)
    }
    update_item_details(id, data) {
        return axios.post('iteams/details/update/' + id, data)
    }
}
export default new CoumpoundService();