<template>
  <router-view></router-view>
</template>

<script>
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import store from "@/store";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4JgIiiDcQUR4V6sxCPvxvFPfxD0brtFA",
  authDomain: "eservices-430ca.firebaseapp.com",
  projectId: "eservices-430ca",
  storageBucket: "eservices-430ca.appspot.com",
  messagingSenderId: "842323580096",
  appId: "1:842323580096:web:e3445d8264e3ca6d2a0925",
  measurementId: "G-GR4X1EYXYX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  store.dispatch("notifications/decreaseUnreadMessage");
  store.commit("notifications/PUSH_NOTIFICATION", {
    message: payload.notification.body,
    link: payload.data.link,
    created_at: payload.data.date,
  });
  store.commit("form/SET_NOTIFY", {
    msg: payload.notification.body,
    type: "Info",
  });
});

getToken(messaging, {
  vapidKey:
    "BEnGvT1V-Bip_azxb7pfR7xDlBLvueIiyHJh6QJYZTG5ZwOKWRuF5pDFZQAT8fFCJglZXurYZ0sFCDhU0tvjwvo",
})
  .then((currentToken) => {
    if (currentToken) {
      console.log(currentToken);
      // if (!localStorage.hasOwnProperty("fcmToken")) {
      //   console.log("token not exist");
      // }
      sendToken(currentToken);
    } else {
      // Show permission request UI
      console.log(
        "No registration token available. Request permission to generate one."
      );
      // ...
    }
  })
  .catch((err) => {
    console.log("An error occurred while retrieving token. ", err);
    // ...
  });
requestPermission();
function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });
}
function sendToken(token) {
  // alert(2);
  axios({
    url: "auth/save-fcm-token", // File URL Goes Here
    method: "POST",
    data: { token },
  }).then((response) => {
    // localStorage.setItem("fcmToken", token);
    console.log(response);
  });
}
export default {
  methods: {},
  mounted() {},
};
</script>
