export const users = {
    action: "fa fa-user",
    title: "users",
    permission: "user",
    items: [
        {
            prefix: "O",
            title: "Roles",
            link: "/roles",
            permission: "user-role-index",
        },
        {
            prefix: "O",
            title: "users view",
            link: "/users",
            permission: "user-index",
        },

    ]
}