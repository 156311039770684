<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div
        class="login"
        :style="`background-image: url(${require('@/assets/img/current/Picture2.png')});`"
      >
        <div class="overlay">
          <div class="content">
            <v-container>
              <div class="row mb-0" :class="classRow">
                <!-- <div class="col-md-3"></div> -->
                <div
                  class="col-md-7 col-lg-5 d-flex justify-center flex-column"
                  :class="offsetLnagCard"
                >
                  <div class="">
                    <v-card
                      :class="directionRTL"
                      class="pa-6 card-shadow border-radius-xl bg-white-transparent"
                    >
                      <div class="w-100">
                        <fade-transition
                          :duration="200"
                          origin="center top"
                          mode="out-in"
                        >
                          <!-- your content here -->
                          <router-view></router-view>
                        </fade-transition>
                      </div>
                    </v-card>
                  </div>
                </div>
                <div
                  class="d-md-block d-none col-md-5"
                  :class="offsetLnagImage"
                >
                  <img
                    style="max-width: 97%"
                    src="@/assets/img/current/logo2.png"
                    alt=""
                  />
                  <div class="text-center">
                    <p class="title-1">{{ $t("Electronic services") }}</p>
                    <p class="title-2">
                      {{ $t("Warehouse and asset management system") }}
                    </p>
                  </div>
                </div>
              </div>
            </v-container>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {};
  },
  computed: {
    directionRTL() {
      return {
        "dire-rtl": this.$vuetify.rtl,
        "dire-ltr": !this.$vuetify.rtl,
      };
    },
    offsetLnagImage() {
      return { "offset-lg-2": this.$vuetify.rtl, test: !this.$vuetify.rtl };
    },
    offsetLnagCard() {
      return { "offset-lg-2": !this.$vuetify.rtl, test: this.$vuetify.rtl };
    },
    classRow() {
      return {
        test: this.$vuetify.rtl,
        "flex-row-reverse": !this.$vuetify.rtl,
      };
    },
  },
  mounted() {},
  created() {
    if (localStorage.hasOwnProperty("lang")) {
      var lang = localStorage.getItem("lang");
    } else {
      var lang = localStorage.setItem("lang", "ar");
    }
    if (lang == "ar") {
      this.$vuetify.rtl = true;
    } else {
      this.$vuetify.rtl = false;
    }
  },
};
</script>
<style lang="css">
html:lang(ar) .section-form {
  direction: rtl;
  /* text-align: right; */
}
</style>
