
class CoumpoundService {

    get_compounds(page, data) {
        return axios.post('compounds?page=' + page, data);
    }
    create_compound() {
        return axios.get('compounds/create');
    }
    store_compound(data) {
        return axios.post('compounds/store', data)
    }
    edit_compound(id) {
        return axios.get('compounds/edit/' + id)
    }
    update_compound(id, data) {
        return axios.post('compounds/update/' + id, data)
    }
    delete_compound(id) {
        return axios.delete('compounds/destroy/' + id)
    }
    assign_user_to_compound(data) {
        return axios.post('users/assign-user-to-manage-compound', data)
    }

    store_city(data) {
        return axios.post('cities/store', data)
    }


    get_units(page, data) {
        return axios.post('units?page=' + page, data);
    }
    store_unit(data) {
        return axios.post('units/store', data)
    }
    update_unit(id, data) {
        return axios.post('units/update/' + id, data)
    }
    delete_unit(id) {
        return axios.delete('units/destroy/' + id)
    }



    get_unit_categories() {
        return axios.get('units/category');
    }
    store_unit_category(data) {
        return axios.post('units/category/store', data)
    }
    update_unit_category(id, data) {
        data.append('_method', 'put')
        return axios.post('units/category/update/' + id, data)
    }
    delete_unit_category(id) {
        return axios.delete('units/category/destroy/' + id)
    }

    get_warehouses(page, data) {
        return axios.post('warehouses?page=' + page, data);
    }
    store_warehouse(data) {
        return axios.post('warehouses/store', data)
    }
    update_warehouse(id, data) {
        return axios.post('warehouses/update/' + id, data)
    }
    delete_warehouse(id) {
        return axios.delete('warehouses/destroy/' + id)
    }
    assign_user_to_warehouse(data) {
        return axios.post('users/assign-user-to-warehouse', data)
    }
    assign_other_users_to_compound(id, data) {
        return axios.post('compounds/assign-other-users-to-compound/' + id, data);
    }
}
export default new CoumpoundService();