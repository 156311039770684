
class UserService {
    get_roles(page) {
        return axios.get('roles?page=' + page);
    }
    get_users(page, data) {

        return axios.post('users/get?page=' + page, data);
    }
    edit_role_user(id, data) {
        return axios.post('users/update-user-role/' + id, data)
    }
    store_user(data) {
        return axios.post('users/store', data);
    }
    switch_user(id, data) {
        return axios.post('users/switch-user-status/' + id, data);
    }
    update_user(id, data) {
        return axios.post('users/update/' + id, data);
    }
    delete_user(id) {
        return axios.delete('users/delete/' + id);

    }
}
export default new UserService();