export const units = {
    action: "mdi-city",
    title: "units",
    permission: "unit",
    items: [
        {
            prefix: "O",
            title: "view categories units",
            link: "/unit-categories",
            permission: "unit-category-index",
        },
        {
            prefix: "O",
            title: "view units",
            link: "/units",
            permission: "unit-index",
        },
    ]
}

export const compounds = {
    action: "mdi-city",
    prefix: "O",
    title: "compounds",
    link: "/compounds",
    permission: "compound-index",
}

export const wharehouses = {
    action: "mdi-warehouse",
    title: "warehouses",
    link: "/warehouses",
    permission: "warehouse-index",
}
