import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const items = {
    path: "/",
    component: DashboardLayout,
    name: "Item-Managment",
    children: [{
        path: "/items",
        name: "Item",
        component: () =>
            import("@/views/Current/Item/index.vue"),
        meta: {
            groupName: "Item",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'item-index',
        },
    },
    {
        path: "/tracking-item",
        name: "TrackingItem",
        component: () =>
            import("@/views/Current/Item/tracking.vue"),
        meta: {
            groupName: "Item",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'item-tracking',
        },
    },

    ]
}

export const itemCategories = {
    path: "/",
    component: DashboardLayout,
    name: "ItemCategory-Managment",
    children: [{
        path: "/items-categories",
        name: "ItemCategory",
        component: () =>
            import("@/views/Current/Item/categories.vue"),
        meta: {
            groupName: "ItemCategory",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'category-item-index',
        },
    },
    ]
}
