import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "./i18n";
import store from "./store";
import "./http/httpClient";
import VueBarcode from "vue-barcode";

Vue.component("barcode", VueBarcode);

import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);


Vue.config.productionTip = false;

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

// Axios setup
import VueAxios from 'vue-axios'


import authHeader from './services/auth-header';
const axios = require('axios')
Vue.use(VueAxios, axios)

import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
window.axios = require('axios')

if (localStorage.hasOwnProperty('lang')) {
    var lang = localStorage.getItem("lang")
} else {
    var lang = localStorage.setItem("lang", 'ar');
}

if (lang == 'ar') {
    vuetify.rtl = true
} else {
    vuetify.rtl = false
}
document.documentElement.lang = lang
// alert(lang)
// alert(vuetify.rtl)


let logoutTimer;

// تعيين وقت المؤقت لعدم النشاط بعد 5 دقائق (300000 مللي ثانية)
const inactivityTimeout = 300000;

// دالة لإعادة تعيين المؤقت عند حدوث نشاط
function resetLogoutTimer() {
    clearTimeout(logoutTimer);
    if (localStorage.hasOwnProperty('accessToken')) {
        logoutTimer = setTimeout(logout, inactivityTimeout);

    }
}

function logout() {
    store.dispatch("auth/logout")
        .then(() => {
            router.push({ path: `/login` });
        })
        .catch((err) => console.log(err));
    resetLogoutTimer();
}

resetLogoutTimer();

document.addEventListener("click", resetLogoutTimer);
document.addEventListener("mousemove", resetLogoutTimer);



import moment from 'moment';
Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('Y-m-d hh:mm:ss')
    }
});

Vue.filter('getMonth', function (value) {
    if (value) {
        const date = new Date();
        date.setMonth(value - 1);
        return date.toLocaleString([], {
            month: 'long',
        });
    }
});

// Vue.prototype.$setupTimers = setupTimers;

require('./assets/css/fonts.css');
require('./assets/css/main.css');
require('./assets/css/custome.css');

function nextCheck(ctx, middleware, index, store) {
    const nextMiddleware = middleware[index];
    if (!nextMiddleware) return ctx.next;

    return (...params) => {
        ctx.next(...params);
        const nextMidd = nextCheck(ctx, middleware, index + 1, store);
        nextMiddleware({ ...ctx, nextMiddleware: nextMidd, store, contextNext: ctx.next });
    };
}

// Auth && Permissions routes setup
router.beforeEach((to, from, next) => {
    if (store.dispatch("ResetData")) {
        if (to.meta.middlewares) {
            const middleware = Array.isArray(to.meta.middlewares)
                ? to.meta.middlewares
                : [to.meta.middlewares];
            const ctx = { from, next, router, to };

            const nextMiddleware = nextCheck(ctx, middleware, 1, store);
            return middleware[0]({ ...ctx, nextMiddleware: nextMiddleware, store, contextNext: ctx.next });
        }

        return next();
    }
});


new Vue({
    router,
    vuetify,
    store,
    i18n,
    icons: {
        iconfont: 'mdi',
    },
    render: (h) => h(App),
}).$mount("#app");