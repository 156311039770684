export default function guest({ nextMiddleware, router, store, contextNext }) {
    if (store.state.auth.loggedIn) {
        //   if (store.state.auth.user.status == "active") {
        return contextNext({
            path: "/dashboard",
        });
        //   }

    }
    return nextMiddleware();
}
