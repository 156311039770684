import Vue from "vue";
import VueRouter from "vue-router";
import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
// import BlankLayout from "../views/Layout/BlankLayout.vue";
// import Middleware from "../middleware";

import {
    PageError,
    others,
    vrPages,
    profilePages,
    usersPages,
    accountPages,
    projectsPages,
    applicationPages,
    ecommercePages,
    pricingPage,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
} from "./tamplate";


import dashboard from "./dashboard"
let dashboardPages = dashboard;
import { compounds, categories, units, wharehouses } from "./basic"
import { items, itemCategories, } from "./item"
import { orders, } from "./order"
import auth from "./auth"
let authPages = auth;

import {
    roles, users,

} from "./user"

Vue.use(VueRouter);

// let FilePage = {
//     path: "/",
//     component: BlankLayout,
//     name: "FilePage",
//     children: [

//     ]
// }

const routes = [
    dashboardPages,
    others,
    usersPages,
    PageError,
    pricingPage,
    profilePages,
    applicationPages,
    ecommercePages,
    accountPages,
    projectsPages,
    vrPages,
    authBasicPages,
    authCoverPages,
    authIllustrationPages,
    authPages,
    units,
    wharehouses,
    // FilePage,
    roles,
    users,
    compounds,
    categories,
    items,
    itemCategories,
    orders,
    {
        path: "*",
        component: AuthCoverLayout,
        name: "Error",
        redirect: "/404",
        children: [
            {
                path: "/test",
                name: "test",
                component: () =>
                    import("@/views/Test.vue"),
            }, {
                path: "/404",
                name: "404",
                component: () =>
                    import("@/views/Error/404.vue"),
            },]
    },
    //     {
    //     path: '*',
    //     component: () =>
    //         import("@/views/Error/404.vue"),
    // }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;