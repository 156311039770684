import router from '../router'
import i18n from '@/i18n.js'
import Vue from "vue";

import CoumpondService from '../services/compound.service';
export const warehouse = {
    namespaced: true,
    state: () => ({
        compound: {},
    }),
    mutations: {
        SET_COMPOUND(state, data) {
            state.compound = data
        }
    },
    actions: {
        // warehouse
        delete({ rootState, }, item) {
            Vue.swal({
                title: this.$i18n.t("are u sure?"),
                text: this.$i18n.t("You won't be able to revert this!"),
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: i18n.t("yes , delete it"),
                cancelButtonText: this.$i18n.t("no , cancel"),
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    return CoumpondService.delete_warehouse(item.id).then(
                        (response) => {
                            // console.log(response)
                            rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                            Vue.swal.fire(i18n.t("Done"), response.data.message, "success");
                        },
                        (error) => {
                            console.log(error);
                            if (error.response.status != 401) {
                                Vue.swal.fire(
                                    i18n.t("Error"),
                                    i18n.t("There error please try again"),
                                    "error"
                                );
                            }
                        }
                    );
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Vue.swal.DismissReason.cancel
                ) {
                    Vue.swal.fire(i18n.t("Cancel"), i18n.t("Cancelled Proccess"), "error");
                }
            });
        },
        add({ commit, rootState }, data) {
            return CoumpondService.store_warehouse(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.table.items.push(response.data.Warehouse)
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                    commit("form/SET_DIALOG", false, { root: true });

                    // router.push({
                    //     name: "Compound",
                    //     params: { message: { msg: response.data.message, type: "Success" } },
                    // });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        // edit_warehouse({ commit, rootState }, item) {
        //     rootState.id = item.id;
        //     console.log(rootState.form)
        //     rootState.form.style_form[0].value = item.name;
        //     rootState.form.style_form[1].value = item.type_id;
        //     rootState.form.style_form[2].value = item.compound_id;
        //     commit("SET_FUNCTION", "update", { root: true });
        //     commit("form/SET_MODAL", { title: 'تعديل المستودع # ' + item.name }, { root: true });
        //     commit('form/SET_DIALOG', true, { root: true });
        // },
        update({ commit, rootState }, data) {
            data.append('_method', 'put')
            return CoumpondService.update_warehouse(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.name = response.data.data.name
                            v.type.name = response.data.data.type.name
                            return v
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                    commit("form/SET_DIALOG", false, { root: true });

                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        assign_user({ commit, rootState }, data) {
            data.append('warehouse_id', rootState.id)
            return CoumpondService.assign_user_to_warehouse(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    commit("form/SET_DIALOG", false, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.user_warehouse = response.data.data
                            return v
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },


        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState, commit }, data) {
            console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (rootState.id) {
                formData.append('compound_id', rootState.id);
            }
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    if (filter.value != '')
                        formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return CoumpondService.get_warehouses(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    commit('SET_COMPOUND', response.data.compound)
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filter;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};