export default {
    action: "mdi-order-bool-descending-variant",
    title: "orders",
    permission: "order",
    items: [
        {
            prefix: "O",
            title: "view orders",
            link: "/orders",
            permission: "order-index",
        },
        {
            prefix: "O",
            title: "create order",
            link: "/orders/create",
            permission: "order-create",
        },
    ]
}
